<template>
  <v-container fluid class="down-top-padding">
    <BaseBreadcrumb :title="page.title" :module_name="page.name"/>
      <v-row>
        <v-col>
          <v-card :disabled="loading">
            <v-card-text>
              <v-row>
                <v-col>
                  <v-list>
                    <v-list-group v-for="(book, key) in books" :key="key" :value="false" prepend-icon="mdi-book">
                      <template v-slot:activator>
                        <v-list-item-title>{{ book.name }}</v-list-item-title>
                      </template>
                      <template v-for="(chapter_page, key2) in book.content">
                        <v-list-group v-if="chapter_page.type === 'chapter'" :key="key2" :value="false" no-action sub-group>
                          <template v-slot:activator>
                            <v-list-item-content>
                              <v-list-item-title>{{ chapter_page.name }}</v-list-item-title>
                            </v-list-item-content>
                          </template>
                          <v-list-item v-for="(page, key3) in chapter_page.pages" :key="key3" @click="getPage(page)" link>
                            <v-list-item-title v-text="page.name"/>
                          </v-list-item>
                        </v-list-group>
                        <v-list-item v-else-if="chapter_page.type === 'page'" @click="getPage(chapter_page)" :key="key2" link>
                          <v-list-item-title v-text="chapter_page.name"/>
                        </v-list-item>
                      </template>
                    </v-list-group>
                  </v-list>
                </v-col>
              </v-row>
              <v-row v-if="selected_page">
                <v-col>
                  <p style="font-size: 24px; font-weight: 600;">{{ selected_page.name }}</p>
                  <div v-html="selected_page.html"/>
                </v-col>
              </v-row>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
  </v-container>
</template>

<script>
import BaseBreadcrumb from "../../components/commonComponents/BaseBreadcrumb";

export default {
  components: {
    BaseBreadcrumb,
  },
  data() {
    return {
      page: {
        title: this.$store.getters.translate("documentation"),
        name: "documentation",
        model: "documentation",
      },
      loading: false,
      books: [],
      selected_page: null,
    }
  },
  created() {
    this.getBooks();
  },
  methods: {
    async getBooks() {
      this.loading = true;
      this.$http
          .get(this.$store.getters.appUrl + "v2/" + this.page.name + "/books")
          .then((response) => {
            this.loading = false;
            this.books = response.data;
          })
          .catch((error) => {
            this.$toasted.error(error);
            this.loading = false;
          });
    },
    async getPage(page) {
      this.loading = true;
      this.$http
          .post(this.$store.getters.appUrl + "v2/" + this.page.name + "/get-page", { page: page.id })
          .then((response) => {
            this.loading = false;
            this.selected_page = response.data;
          })
          .catch((error) => {
            this.$toasted.error(error);
            this.loading = false;
          });
    },
  },
}
</script>